<template>
  <div class="order-detail-container">
    <div class="buttonbc">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="../../../assets/back.png" alt="">
      </el-button>
    </div>
    <div class="cont">
      <el-form style="width: 600px;" :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
        <el-form-item label="活动名称" prop="name">
          <el-input placeholder="请输入30个字符以内的名称" :maxlength="30" v-model="txInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="starting_time">
          <el-date-picker :picker-options="timeOptions" format="yyyy-MM-dd HH:mm" v-model="order_time_range"
            @change="updateTime" value-format="timestamp" type="datetimerange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="优惠类型" prop="promotion_period">
          <el-select v-model="txInfo.promotion_period" placeholder="请选择">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          首件减免
        </el-form-item>

        <el-form-item style="margin-bottom: 0;" label="适用范围" prop="goods_limit">
          <el-radio-group v-model="txInfo.goods_limit">
            <el-radio :label="0">全部自有商品</el-radio>
            <el-radio :label="1">自定义自有商品</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="txInfo.goods_limit == 0" label="首件减免折扣" prop="discount_value">
          <div style="display: inline-block;">
            <el-input type="number" style="width: 100px;" v-model.trim="txInfo.discount_value"></el-input>
          </div>
          <span style="padding-left: 12px;">折</span>
        </el-form-item>
        <el-form-item style="margin-bottom: 0;" v-if="txInfo.goods_limit == 1" prop="goods_data" class="goods-name-width">
          <el-button type="primary" size="small" @click="showDialog = true">选择商品</el-button>
        </el-form-item>
        <!-- 商品选择区 start -->
        <el-form-item prop="goods_list" v-if="txInfo.goods_limit == 1" style="width: 100%" class="goods-select">
          <en-table-layout :tableData="goodsData" style="width:1000px" ref="tableWrap">
            <template slot="table-columns">
              <el-table-column label="编号" :show-overflow-tooltip="true" width="200" prop="goods_id">

              </el-table-column>
              <el-table-column label="图片" width="60" class-name="goods-cover-wrapper">
                <template slot-scope="{ row }">
                  <el-popover placement="right" trigger="hover">
                    <img :src="row.thumbnail" alt style="width: 300px" />
                    <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="商品/礼包名称" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span v-html="scope.row.goods_name"></span>
                </template>
              </el-table-column>
              <el-table-column label="销售价格" width="100" prop="revisePrice">
                <template slot-scope="scope">{{
                  scope.row.revise_price | unitPrice("￥")
                }}</template>
              </el-table-column>
              <!-- v-if="MixinIsFormEnterprise" -->
              <el-table-column label="折扣" width="100" prop="shopProfit">
                <template slot-scope="{row}">
                  <el-input v-model.trim="row.discount_value" onkeyup="value=value.replace(//[^\d.]/g,'')"
                    @input="moveOver"></el-input>
                  <span v-if="row.discount_value > 10" style="color:red;font-size:12px">请输入0.1~9.9之间的数字，精确到小数点后1位</span>
                </template>
              </el-table-column>
              <el-table-column label="优惠后首件价格" width="140" prop="discount_price">
                <template slot-scope="scope">{{
                  scope.row.discount_price | unitPrice("￥")
                }}</template>
              </el-table-column>

              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </template>
          </en-table-layout>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 140px;" type="primary" @click="accountSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <en-own-goods-picker-fx :delivery_method="2" :offline_store_pay="1" :isSoldSeparatelyEnable="false" :show="showDialog"
      :showChooseBtn="false" :default-data="goodsData" @close="showDialog = false" @chooseAll="allChoose" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_Goods from '@/api/goods';
import { addPieceActivity, editPieceActivity, getPieceActivity } from '@/api/shopSetting';
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
export default {
  name: "firstPieceEdit",
  components: {
    EnTableLayout,
  },
  data () {
    return {
      moveOver: '',
      goodsData: [],
      firstPriceShowFlag: true,
      showDialog: false,//商品选择器
      order_time_range: '',
      typeOptions: [
        { label: '每天', value: 1 },
        { label: '每周', value: 2 },
        { label: '每月', value: 3 },
        { label: '每年', value: 4 },
        { label: '终身', value: 5 },
      ],
      timeOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        }
      },
      txInfo: {//审核表单内容
        name: '',//活动名称
        status: 0,//促销活动状态
        starting_time: '',//活动开始时间
        ending_time: '',//活动结束时间
        promotion_period: 1,//优惠类型
        goods_limit: 0,//适用范围
        discount_type: 1,//优惠类型 1:折扣
        discount_value: '',//折扣金额
        promotion_type: 1,//促销类型 1:首件优惠
        goods_list: [],//商品列表
      },

      txRules: {//审核表单验证
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        starting_time: [
          { required: true, message: '请选择活动时间', trigger: 'change' }
        ],
        promotion_period: [
          { required: true, message: '请选择优惠类型', trigger: 'change' }
        ],
        discount_value: [
          { required: true, message: '请输入首件减免折扣', trigger: 'blur' }
        ],
        goods_list: [
          { required: true, message: '请选择商品', trigger: 'change' }
        ]
      },
    };
  },
  watch: {
    goodsData: function () {
      // 如果是编辑，价格的第一次回显使用接口返回的价格，而不是客户端自己计算的
      if (
        (this.$route.query &&
          this.$route.query.id &&
          this.firstPriceShowFlag) ||
        (this.comboId && this.firstPriceShowFlag)
      ) {
        this.firstPriceShowFlag = !this.firstPriceShowFlag;
      } else {
        this.getPrice();
      }
    },
  },

  async mounted () {
    if (this.$route.query.id) {
      const dataform = await getPieceActivity(this.$route.query.id)
      this.txInfo = dataform.data
      this.txInfo.discount_value = this.txInfo.discount_value * 10
      this.order_time_range = [this.txInfo.starting_time * 1000, this.txInfo.ending_time * 1000 - 86400]

      if (this.txInfo.goods_limit == 1) {
        this.txInfo.goods_list.forEach(item => {
          item.discount_value = item.discount_value * 10
        })
        this.goodsData = this.txInfo.goods_list;
      }
    }
    let that = this;
    this.moveOver = this.debounce(function () {
      that.getPrice();
    }, 200)
  },

  methods: {
    //防抖
    debounce (fn, delay = 300) {   //默认300毫秒
      let timer;
      return function () {
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn.apply(this, args);   // this 指向vue
        }, delay);
      };
    },
    getPrice (type = 0, key = "") {
      console.log("价格改了");
      if (key === "") {
      } else {
        this.txInfo[key] = type;
      }
      this.goodsData.forEach(res => {
        if (!res.discount_value) {
          res.discount_value = ''
          res.discount_price = ''
        } else {
          res.discount_price = res.revise_price * (res.discount_value / 10);
        }
      })
      this.txInfo = {
        ...this.txInfo,
        ...this.goodsData.reduce(
          (p, c) => {
            // 利润率计算
            p.discount_price = p.revise_price * (p.discount_value / 100)
            return p;
          },
          {
            discount_price: 0,
          }
        ),

      };
      console.log(this.goodsData);
      this.$forceUpdate();
    },
    updateTime (time) {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        this.order_time_range[0] < Date.now() ? this.order_time_range[0] = Date.now() : ''
        if (this.order_time_range[0] > this.order_time_range[1]) {
          this.$message.error("开始时间不能大于结束时间");
          this.order_time_range = [];
          return;
        }
        if (typeof this.order_time_range[0] === "string") {
          this.txInfo.starting_time =
            this.order_time_range[0] / 1000;
          this.txInfo.ending_time =
            this.order_time_range[1] / 1000 +
            86400 - 1;
        } else {
          this.txInfo.starting_time =
            this.order_time_range[0] / 1000;
          this.txInfo.ending_time =
            this.order_time_range[1] / 1000 + 86400 - 1;
        }
      } else {
        this.txInfo.starting_time = ''
        this.txInfo.ending_time = ''
      }
    },
    /**删除选择的商品 */
    handleDelete (index, row) {
      this.goodsData.splice(index, 1);
      this.txInfo.goods_list = this.goodsData
    },
    /** 保存商品选择器选择的商品 */
    allChoose (list) {
      const originGoodsId = this.goodsData.map((item) => {
        return item.goods_id;
      });
      list.forEach((i) => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, "num", 1);
          this.$set(i, "goods_alias", i.goods_name);
          this.$set(i, "not_put", true);

          this.$set(i, "discount_value", 10);
          this.$set(i, "discount_price", i.revise_price);

          this.goodsData.push(i);
          this.txInfo.goods_list = this.goodsData
        }
      });
    },
    /**提交 */
    accountSubmit () {
      this.$refs.txInfo.validate(valid => {
        let datas = JSON.parse(JSON.stringify(this.txInfo))
        if (valid) {
          if (datas.goods_limit == 1) {
            delete datas.discount_value
            delete datas.discount_price
            datas.goods_list = datas.goods_list.map(item => {
              let obj = {};
              obj.goods_id = item.goods_id;
              obj.discount_value = item.discount_value / 10;
              if (item.goods_type == 'PACK') {
                obj.goods_type = 2
              } else if (item.goods_type == 'NORMAL') {
                obj.goods_type = 1
              } else {
                obj.goods_type = item.goods_type
              }
              return obj;
            })
          } else {
            datas.discount_value = datas.discount_value / 10
          }
          if (this.txInfo.id) {//编辑         
            editPieceActivity(datas).then((response) => {
              this.$message.success('活动修改成功！');
              this.$router.go(-1);
            })
          } else {//添加
            addPieceActivity(datas).then((response) => {
              this.$message.success('活动添加成功！');
              this.$router.go(-1);
            })
          }
        }
      });
    },


  }
};
</script>

<style lang="scss" scoped>
.cont {
  /deep/ .wlcz {
    list-style: none;
    margin-bottom: 0;
    padding-left: 10px;
  }

  /deep/ .cell {
    text-align: center;
  }

  /deep/ .is-leaf {
    /* border: 1px solid #000000 !important; */
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
  }

  /deep/ .el-table__row td {
    /* border: 1px solid #000000 !important; */
    text-align: center;
    font-size: 16px;
  }

  /deep/ .el-table--border {
    /* border: 1px solid #000000 !important; */
  }
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  clear: both;
  margin-left: 30px;
  padding-bottom: 20px;
}



.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}

/deep/ .el-table__row {
  border-collapse: separate;
  border-bottom: 1px solid #ebeef5;
}
</style>

